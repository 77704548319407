import { FC, useEffect, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Spinner } from "../../components/Spinner/Spinner";
import "./index.css";
import "./crop.css";
import { API } from "../../config/api";

const getNewUrlOfCrop = (url: string, area: Area, k: number) => {
  const newUrl = url.replace("/raw/", "/image/");
  const croppedUrl = `${newUrl}?fit=crop&crop-x=${Math.floor(area.x * k)}&crop-y=${Math.floor(area.y * k)}&crop-w=${
    Math.floor(area.width * k) - 1
  }&crop-h=${Math.floor(area.height * k) - 1}`;
  return croppedUrl;
};

// const getThumbnail = (url: string) => {
//   const newUrl = url.replace("/raw/", "/image/");
//   // const result = `${newUrl}?w=800&q=50`;
//   const result = `${newUrl}?w=800`;
//   return result;
// };

// const FORMATS = ["1:1", "4:3", "9:16", "3:4", "16:9"];

const FORMATS = {
  image: [
    {
      value: "1952:1919",
      label: "1:1",
    },
    {
      value: "4:3",
      label: "4:3",
    },
    {
      value: "9:16",
      label: "9:16",
    },
    {
      value: "3:4",
      label: "3:4",
    },
    {
      value: "16:9",
      label: "16:9",
    },
  ],

  // ["1:1", "4:3", "9:16", "3:4", "16:9"],
  cover: [
    {
      value: "1231:1522",
      label: "5:6",
    },
    {
      value: "1634:1298",
      label: "6:5",
    },
    {
      value: "1522:1522",
      label: "1:1",
    },
    // {
    //   value: "1231:1522",
    //   label: "v",
    // },
    // {
    //   value: "1634:1298",
    //   label: "h",
    // },
    // {
    //   value: "1522:1522",
    //   label: "s",
    // },
  ],
};

interface Props {
  aspectX: number;
  aspectY: number;
  type: "image" | "cover";
}

export const Crop: FC<Props> = ({ type }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [format, setFormat] = useState(FORMATS[type][0].value);
  const [zoom, setZoom] = useState(1);
  // const [url, setUrl] = useState("");
  const [data, setData] = useState<any | null>(null);
  // const [size, setSize] = useState<any | null>(null);
  const [area, setArea] = useState<Area>();
  const [isLoaded, setIsLoaded] = useState(false);

  const { id, order_id } = useParams();
  const history = useNavigate();

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setArea(croppedAreaPixels);
  };

  const onMediaLoaded = (mediaSize: any) => {
    setIsLoaded(true);
  };

  const submit = async () => {
    if (area) {
      // const crop = getNewUrlOfCrop(data.originalUrl, area, data.k);
      try {
        if (type === "image" && id) {
          await API.images.cropImage({ id: +id, format, area, url: data.originalUrl, k: data.k });
          // await axios.patch("images/crop", { id, format, area, url: data.originalUrl, k: data.k });
        } else if (type === "cover" && order_id) {
          const formatLabel = FORMATS.cover.find((item) => item.value === format)!.label;
          await API.cover.cropCover({ order_id, format: formatLabel, url: data.originalUrl, k: data.k, area });
          // await axios.patch("covers", { order_id, crop, format: formatLabel, url: data.originalUrl, k: data.k });
        }
        history(-1);
      } catch (error) {
        console.log("error:", error);
      }
    }
  };

  const getImage = async () => {
    try {
      if (type === "image") {
        const { data } = await axios.get(`images/url/${order_id}/${id}`);
        setData(data);
        // setUrl(getThumbnail(data));
      } else if (type === "cover") {
        const { data } = await axios.get(`covers/url/${order_id}`);
        setData(data);
        // setUrl(getThumbnail(data.url));
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    getImage();
  }, []);

  if (!data)
    return (
      <div className="absolute-center">
        <Spinner />
      </div>
    );

  const [x, y] = format.split(":");

  return (
    <div>
      <div className="crop-container ">
        <Cropper
          image={data.url}
          crop={crop}
          zoom={zoom}
          aspect={Number(x) / Number(y)}
          // aspect={aspectX / aspectY}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          onMediaLoaded={onMediaLoaded}
        />
      </div>
      {isLoaded ? null : (
        <div className="absolute-center">
          <Spinner />
        </div>
      )}
      <div className="controls">
        {/* <select value={format} onChange={(e) => setFormat(e.target.value)} className="select">
          {FORMATS[type].map((item) => (
            <option value={item.value}>{item.label}</option>
          ))}
        </select> */}
        <div className="crop_items_block">
          {FORMATS[type].map((item) => {
            const [a, b] = item.label.split(":");
            return (
              <span
                className={`crop_item_block ${format === item.value ? "crop_item_block-active" : ""}`}
                onClick={() => setFormat(item.value)}
              >
                {item.label}
              </span>
            );
          })}
        </div>
        <input
          type="range"
          id="cowbell"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => setZoom(Number(e.target.value))}
        />

        <div className="buttons_block">
          <button className="btn_crop btn_crop-submit" onClick={submit}>
            Сохранить
          </button>
          <button className="btn_crop btn_crop-cancel" onClick={() => history(-1)}>
            Назад
          </button>
        </div>
      </div>
    </div>
  );
};
