import axios, { AxiosError } from "axios";
import { Area } from "react-easy-crop";
import { SITE_URL } from "./config";

export const BASE_URL = process.env.NODE_ENV === "production" ? `${SITE_URL}api/` : "http://localhost:5555/api/";

axios.defaults.baseURL = BASE_URL;
// axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

axios.interceptors.request.use(
  (config) => {
    const URL = config.url;
    if (URL?.includes("admin")) {
      const token = localStorage.getItem("adminToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    } else {
      const token = localStorage.getItem("token") || localStorage.getItem("adminToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      return window.location.assign("/email");
    }
  }
);

export const API = {
  orders: {
    async getOrders() {
      try {
        const { data } = await axios.get("orders");
        return data;
      } catch (error) {
        console.log("🚀 ~ getOrders ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getOrder(order_id: string) {
      try {
        const { data } = await axios.get("orders/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getOrders ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getAlbum(order_id: string) {
      try {
        const { data } = await axios.get("orders/album/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getOrders ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getPreview(order_id: string) {
      try {
        const { data } = await axios.get("orders/preview/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getOrders ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
  },
  images: {
    async getImages(order_id: string) {
      try {
        const { data } = await axios.get("images/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getImages ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async addImages(dto: { photos: any; order_id: string }) {
      try {
        const { data } = await axios.post("images", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ addImages ~ error:", error);
        await API.logs.addLog("error_image_uploading_our_server", dto.order_id);
        throw new Error("Something went wrong");
      }
    },
    async changeOrder(dto: { dragId: number; dropId: number }) {
      try {
        const { data } = await axios.patch("images/index", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ changeOrder ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async deleteImage(id: number) {
      try {
        const { data } = await axios.delete("images/" + id);
        return data;
      } catch (error) {
        console.log("🚀 ~ deleteImage ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async changePhoto(dto: { id: number; url: string }) {
      try {
        const { data } = await axios.post("images/other", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ changePhoto ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async cropImage(dto: { id: number; format: string; area: Area; url: string; k: number }) {
      try {
        const { data } = await axios.patch("images/crop", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ cropImage ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
  },
  cover: {
    async cropCover(dto: { order_id: string; format: string; url: string; k: number; area: Area }) {
      try {
        const { data } = await axios.patch("covers/crop", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ cropCover ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getCover(order_id: string) {
      try {
        const { data } = await axios.get("covers/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getCover ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async deleteCover(order_id: string) {
      try {
        const { data } = await axios.patch("covers", { order_id, url: "" });
        return data;
      } catch (error) {
        console.log("🚀 ~ getCover ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async changeCover(dto: {
      order_id: string;
      color?: string;
      url?: string;
      text?: string;
      font?: string;
      text_color?: string;
    }) {
      try {
        const { data } = await axios.patch("covers", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ getCover ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    // async addCover(dto: { order_id: string; url: string }) {
    //   try {
    //     const { data } = await axios.patch("covers", dto);
    //     return data;
    //   } catch (error) {
    //     console.log("🚀 ~ getCover ~ error:", error);
    //     throw new Error("Something went wrong");
    //   }
    // },
  },
  addresses: {
    async changeAmount(dto: { address_id: string; amount: number }) {
      try {
        const { data } = await axios.patch("address", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ changeAmount ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
  },
  promocodes: {
    async getPromocode() {
      try {
        const { data } = await axios.get("promocodes");
        return data;
      } catch (error) {
        console.log("🚀 ~ getPromocode ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getPromocodeByOrder(order_id: string) {
      try {
        const { data } = await axios.get("promocodes/order/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getPromocode ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
  },
  users: {
    async login(dto: any) {
      try {
        const { data } = await axios.post("users", dto);
        return data;
      } catch (error) {
        return error;
      }
    },
  },
  chats: {
    async getChats() {
      try {
        const { data } = await axios.get("chats");
        return data;
      } catch (error) {
        return error;
      }
    },
    async sendMessage(text: string) {
      try {
        const { data } = await axios.post("chats/site", { text });
        return data;
      } catch (error) {
        return error;
      }
    },
  },
  admin: {
    async getAddresses(order_id: string) {
      const { data } = await axios.get("admin/addresses/" + order_id);
      return data;
    },
    async changeTrack({ id, track }: { id: string; track: string }) {
      const { data } = await axios.patch("admin/addresses/track", { id, track });
      return data;
    },
    async getUserInfo(user_id: string) {
      const { data } = await axios.get("admin/users/contact/" + user_id);
      return data;
    },
    async getUserIdByEmail(email: string) {
      const { data } = await axios.get("admin/users/email/" + email);
      return data;
    },
    async getUserIdByTelegram(telegram: string) {
      const { data } = await axios.get("admin/users/telegram/" + telegram);
      return data;
    },
    async getUserIdByParams(params: string) {
      const { data } = await axios.get("admin/users/params/" + params);
      return data;
    },
    async getUser(user_id: string) {
      const { data } = await axios.get("admin/users/" + user_id);
      return data;
    },
    async getOrders(status: string = "all") {
      const { data } = await axios.get("admin/orders?status=" + status);
      return data;
    },
    async getUsers() {
      const { data } = await axios.get("admin/users");
      return data;
    },
    async getCover(order_id: string) {
      try {
        const { data } = await axios.get("admin/cover/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getCover ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getImages(order_id: string) {
      try {
        const { data } = await axios.get("admin/images/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getImages ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getOrderInfo(order_id: string) {
      try {
        const { data } = await axios.get("admin/orders/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getOrderInfo ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getActualPromocode(order_id: string) {
      try {
        const { data } = await axios.get("admin/promocodes/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getActualPromocode ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getPdfAlbum(order_id: string) {
      try {
        const { data } = await axios.get("admin/album/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getPdfAlbum ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getJpgAlbum(order_id: string) {
      try {
        const { data } = await axios.get("admin/album/jpg/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getJpgAlbum ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async changeColorCover(dto: { id: string; color: string }) {
      try {
        const { data } = await axios.patch("admin/cover", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ getJpgAlbum ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async changeTextCover(dto: { id: string; text: string }) {
      try {
        const { data } = await axios.patch("admin/cover", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ changeTextCover ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async changeCover(dto: { id: string; text?: string; color?: string; text_color?: string }) {
      try {
        const { data } = await axios.patch("admin/cover", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ changeCover ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async addFirstImageAsCover(dto: { order_id: string }) {
      try {
        const { data } = await axios.patch("admin/cover/default", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ addFirstImageAsCover ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async togglePaid(dto: { order_id: string; price: number }) {
      try {
        const { data } = await axios.patch("admin/orders/paid", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ togglePaid ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async changeOrderStatus(dto: { order_id: string; status: string }) {
      try {
        const { data } = await axios.patch("admin/orders/status", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ togglePaid ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async markOrderAsTest(order_id: string) {
      try {
        const { data } = await axios.patch("admin/orders/test", { order_id });
        return data;
      } catch (error) {
        console.log("🚀 ~ togglePaid ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async addPost(dto: { order_id: string; post_id: string }) {
      try {
        const { data } = await axios.patch("admin/orders/post", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ togglePaid ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getLogs(order_id: string) {
      try {
        const { data } = await axios.get("admin/logs/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getResult ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getChats(order_id: string) {
      try {
        const { data } = await axios.get("admin/chats/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getChats ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async sendMessage(dto: { user_id: string; text: string }) {
      try {
        const { data } = await axios.post("admin/chats", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ getChats ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async addEmptyPartner() {
      try {
        const { data } = await axios.post("admin/partners/create");
        return data;
      } catch (error) {
        console.log("🚀 ~ getChats ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async makePartner(dto: { order_id: string }) {
      try {
        const { data } = await axios.post("admin/partners", dto);
        return data;
      } catch (error) {
        console.log("🚀 ~ getChats ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getPartner(order_id: string) {
      try {
        const { data } = await axios.get("admin/partners/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getChats ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getPartnerInfo(partner_id: string) {
      try {
        const { data } = await axios.get("admin/partners/info/" + partner_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getChats ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getPartners() {
      try {
        const { data } = await axios.get("admin/partners");
        return data;
      } catch (error) {
        console.log("🚀 ~ getResult ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async deleteFolderOfOrder(order_id: string) {
      try {
        await axios.patch("admin/images/delete/folder", { order_id });
      } catch (error) {
        console.log("🚀 ~ getResult ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
  },
  logs: {
    async addLog(action: string, order_id?: string, text?: string) {
      try {
        const { data } = await axios.post("logs", { action, order_id, text });
        return data;
      } catch (error) {
        console.log("🚀 ~ getResult ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
  },
  partner: {
    async getInfo() {
      try {
        const { data } = await axios.get("partners/info");
        return data;
      } catch (error) {
        console.log("🚀 ~ getResult ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async isPartner() {
      try {
        const { data } = await axios.get("partners");
        return data;
      } catch (error) {
        console.log("🚀 ~ getResult ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
  },
  payments: {
    async getResult(order_id: string) {
      try {
        const { data } = await axios.get("payments/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getResult ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getPreview(order_id: string) {
      try {
        const { data } = await axios.get("payments/preview/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getResult ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async getPaymentUrl(order_id: string) {
      try {
        const { data } = await axios.get("payments/url/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getPaymentUrl ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
    async submitFreeOrder(order_id: string) {
      try {
        const { data } = await axios.patch("payments/free/" + order_id);
        return data;
      } catch (error) {
        console.log("🚀 ~ getPaymentUrl ~ error:", error);
        throw new Error("Something went wrong");
      }
    },
  },
};
