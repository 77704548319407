import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const PartnerLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();

  const clickHandler = async () => {
    if (password.length > 3) {
      setIsLoading(true);
      try {
        const response = await axios.post("users/login", { email, password });
        if (response.status === 200) {
          localStorage.setItem("token", response.data.token);
          history("/partner");
          setIsLoading(false);
        } else {
          setError("Неправильные email или пароль");
          setIsLoading(false);
        }
      } catch (error) {
        setError("Неправильные email или пароль");
        setIsLoading(false);
      }
    } else {
      setError("Введите корректный email");
    }
  };

  return (
    <div className="wrapper wrapper-medium">
      <h2>Укажи свой логин и пароль</h2>
      <div className="input_block">
        <input value={email} placeholder="логин" onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className="input_block" style={{ marginTop: "20px" }}>
        <input placeholder="пароль" autoFocus onChange={(e) => setPassword(e.target.value)} />
      </div>
      {error.length > 0 && <div className="error">{error}</div>}
      <div style={{ marginTop: "20px" }}>
        <button onClick={clickHandler} className="btn btn-main" disabled={isLoading}>
          ДАЛЕЕ
        </button>
      </div>
    </div>
  );
};

export default PartnerLogin;
