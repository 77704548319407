import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Crop } from "./pages/Admin/Crop";
import "./config/api";
import "./App.css";
import "./css/index.css";
import PartnerLogin from "./pages/Client/Login/PartnerLogin";

const Landing = lazy(() => import("./pages/Client/Landing/Landing"));
const Email = lazy(() => import("./pages/Client/Login/Email"));
const Login = lazy(() => import("./pages/Client/Login/Login"));
const Album = lazy(() => import("./pages/Client/Album/Album"));
const Cover = lazy(() => import("./pages/Client/Cover/Cover"));
const Preview = lazy(() => import("./pages/Client/Preview/Preview"));
const Address = lazy(() => import("./pages/Client/Address/Address"));
const Payment = lazy(() => import("./pages/Client/Payment/Payment"));
const Orders = lazy(() => import("./pages/Client/Orders/Orders"));
const Order = lazy(() => import("./pages/Client/Order/Order"));
const Partner = lazy(() => import("./pages/Client/Partner/Partner"));
const PaymentStatus = lazy(() => import("./pages/Client/Payment/PaymentStatus"));
const NoRoute = lazy(() => import("./pages/NoRoute"));
const Admin = lazy(() => import("./pages/Admin/Admin"));
const AdminOrders = lazy(() => import("./pages/Admin/Orders"));
const AdminUsers = lazy(() => import("./pages/Admin/Users"));
const AdminUser = lazy(() => import("./pages/Admin/User"));
const AdminLogin = lazy(() => import("./pages/Admin/Login"));
const AdminPartners = lazy(() => import("./pages/Admin/Partners"));
const AdminPartner = lazy(() => import("./pages/Admin/Partner"));
const DesignAlbum = lazy(() => import("./pages/Client/Design/Album"));
const DesignWait = lazy(() => import("./pages/Client/Design/Wait"));

const queryClient = new QueryClient();

function App() {
  return (
    <div
    className="App"
    >
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<div>...</div>}>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/email" element={<Email />} />
            <Route path="/album/:order_id" element={<Album />} />
            <Route path="/albumd/:order_id" element={<DesignAlbum />} />
            <Route path="/waitd/:order_id" element={<DesignWait />} />
            <Route path="/cover/:order_id" element={<Cover />} />
            <Route path="/preview/:order_id" element={<Preview />} />
            <Route path="/address/:order_id" element={<Address />} />
            <Route path="/payment/:order_id" element={<Payment />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/partner" element={<Partner />} />
            <Route path="/partnerlogin" element={<PartnerLogin />} />
            <Route path="/orders/:order_id" element={<Order />} />
            <Route path="/payments/status/:order_id" element={<PaymentStatus />} />
            <Route path="/admin/orders/:order_id" element={<Admin />} />
            <Route path="/admin/orders" element={<AdminOrders />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/users" element={<AdminUsers />} />
            <Route path="/admin/users/:user_id" element={<AdminUser />} />
            <Route path="/admin/partners" element={<AdminPartners />} />
            <Route path="/admin/partners/:id" element={<AdminPartner />} />
            <Route path="/images/crop/:order_id/:id" element={<Crop aspectX={1919} aspectY={1952} type="image" />} />
            <Route path="/cover/crop/:order_id" element={<Crop aspectX={1231} aspectY={1522} type="cover" />} />
            <Route path="*" element={<NoRoute />} />
          </Routes>
        </Suspense>
      </QueryClientProvider>
    </div>
  );
}

export default App;
